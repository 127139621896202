import type { UserAbility } from '@/plugins/casl/AppAbility'

export const ordersWorkerAbilities: UserAbility[] = [
  // Auth
  {
    action: 'read',
    subject: 'Auth'
  },

  // Orders (collection)
  {
    action: 'read',
    subject: 'Orders'
  },
  {
    action: 'create',
    subject: 'Orders'
  },
  {
    action: 'update',
    subject: 'Orders'
  },
  // Cannot delete orders
  // {
  //   action: 'delete',
  //   subject: 'Orders'
  // },

  // Order (singular)
  {
    action: 'read',
    subject: 'Order'
  },
  {
    action: 'create',
    subject: 'Order'
  },
  {
    action: 'update',
    subject: 'Order'
  },
  // Cannot delete orders
  // {
  //   action: 'delete',
  //   subject: 'Order'
  // },

  // Products
  {
    action: 'read',
    subject: 'Products'
  },

  // Product (singular)
  {
    action: 'read',
    subject: 'Product'
  },

  // Categories
  {
    action: 'read',
    subject: 'Categories'
  },

  // Category (singular)
  {
    action: 'read',
    subject: 'Category'
  }

  // Other subjects not accessible to this role
  // Coupons
  // {
  //   action: 'read',
  //   subject: 'Coupons'
  // },
  // {
  //   action: 'create',
  //   subject: 'Coupons'
  // },
  // {
  //   action: 'update',
  //   subject: 'Coupons'
  // },
  // {
  //   action: 'delete',
  //   subject: 'Coupons'
  // },

  // Shipping
  // {
  //   action: 'read',
  //   subject: 'Shipping'
  // },
  // {
  //   action: 'create',
  //   subject: 'Shipping'
  // },
  // {
  //   action: 'update',
  //   subject: 'Shipping'
  // },
  // {
  //   action: 'delete',
  //   subject: 'Shipping'
  // },

  // Settings
  // {
  //   action: 'read',
  //   subject: 'Settings'
  // },
  // {
  //   action: 'create',
  //   subject: 'Settings'
  // },
  // {
  //   action: 'update',
  //   subject: 'Settings'
  // },
  // {
  //   action: 'delete',
  //   subject: 'Settings'
  // },

  // Trash
  // {
  //   action: 'read',
  //   subject: 'Trash'
  // },
  // {
  //   action: 'create',
  //   subject: 'Trash'
  // },
  // {
  //   action: 'update',
  //   subject: 'Trash'
  // },
  // {
  //   action: 'delete',
  //   subject: 'Trash'
  // },

  // PointOfSale
  // {
  //   action: 'read',
  //   subject: 'PointOfSale'
  // },
  // {
  //   action: 'create',
  //   subject: 'PointOfSale'
  // },
  // {
  //   action: 'update',
  //   subject: 'PointOfSale'
  // },
  // {
  //   action: 'delete',
  //   subject: 'PointOfSale'
  // },

  // Tools
  // {
  //   action: 'read',
  //   subject: 'Tools'
  // },
  // {
  //   action: 'create',
  //   subject: 'Tools'
  // },
  // {
  //   action: 'update',
  //   subject: 'Tools'
  // },
  // {
  //   action: 'delete',
  //   subject: 'Tools'
  // },

  // Birthdays
  // {
  //   action: 'read',
  //   subject: 'Birthdays'
  // },
  // {
  //   action: 'create',
  //   subject: 'Birthdays'
  // },
  // {
  //   action: 'update',
  //   subject: 'Birthdays'
  // },
  // {
  //   action: 'delete',
  //   subject: 'Birthdays'
  // },

  // Updates
  // {
  //   action: 'read',
  //   subject: 'Updates'
  // },
  // {
  //   action: 'create',
  //   subject: 'Updates'
  // },
  // {
  //   action: 'update',
  //   subject: 'Updates'
  // },
  // {
  //   action: 'delete',
  //   subject: 'Updates'
  // }
]
