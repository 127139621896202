import type { UserAbility } from '@/plugins/casl/AppAbility'

export const adminAbilities: UserAbility[] = [
  // Auth
  {
    action: 'read',
    subject: 'Auth'
  },

  // Products
  {
    action: 'read',
    subject: 'Products'
  },
  {
    action: 'create',
    subject: 'Products'
  },
  {
    action: 'update',
    subject: 'Products'
  },
  {
    action: 'delete',
    subject: 'Products'
  },

  // Product (singular)
  {
    action: 'read',
    subject: 'Product'
  },

  {
    action: 'create',
    subject: 'Product'
  },
  {
    action: 'update',
    subject: 'Product'
  },
  {
    action: 'delete',
    subject: 'Product'
  },
  //Customers
  {
    action: 'read',
    subject: 'Customers'
  },
  {
    action: 'create',
    subject: 'Customers'
  },
  {
    action: 'update',
    subject: 'Customers'
  },
  {
    action: 'delete',
    subject: 'Customers'
  },

  // Orders (collection)
  {
    action: 'read',
    subject: 'Orders'
  },
  {
    action: 'create',
    subject: 'Orders'
  },
  {
    action: 'update',
    subject: 'Orders'
  },
  {
    action: 'delete',
    subject: 'Orders'
  },
  // Reports
  {
    action: 'read',
    subject: 'Reports'
  },
  {
    action: 'create',
    subject: 'Reports'
  },
  {
    action: 'update',
    subject: 'Reports'
  },
  {
    action: 'delete',
    subject: 'Reports'
  },

  // Order (singular)
  {
    action: 'read',
    subject: 'Order'
  },
  {
    action: 'create',
    subject: 'Order'
  },
  {
    action: 'update',
    subject: 'Order'
  },
  {
    action: 'delete',
    subject: 'Order'
  },

  // Coupons
  {
    action: 'read',
    subject: 'Coupons'
  },
  {
    action: 'create',
    subject: 'Coupons'
  },
  {
    action: 'update',
    subject: 'Coupons'
  },
  {
    action: 'delete',
    subject: 'Coupons'
  },

  // Categories
  {
    action: 'read',
    subject: 'Categories'
  },
  {
    action: 'create',
    subject: 'Categories'
  },
  {
    action: 'update',
    subject: 'Categories'
  },
  {
    action: 'delete',
    subject: 'Categories'
  },

  //Category
  {
    action: 'read',
    subject: 'Category'
  },
  {
    action: 'create',
    subject: 'Category'
  },
  {
    action: 'update',
    subject: 'Category'
  },
  {
    action: 'delete',
    subject: 'Category'
  },

  // Attributes
  {
    action: 'read',
    subject: 'Attributes'
  },
  {
    action: 'create',
    subject: 'Attributes'
  },
  {
    action: 'update',
    subject: 'Attributes'
  },
  {
    action: 'delete',
    subject: 'Attributes'
  },

  // SizeChart
  {
    action: 'read',
    subject: 'SizeChart'
  },
  {
    action: 'create',
    subject: 'SizeChart'
  },
  {
    action: 'update',
    subject: 'SizeChart'
  },
  {
    action: 'delete',
    subject: 'SizeChart'
  },

  // Shipping
  {
    action: 'read',
    subject: 'Shipping'
  },
  {
    action: 'create',
    subject: 'Shipping'
  },
  {
    action: 'update',
    subject: 'Shipping'
  },
  {
    action: 'delete',
    subject: 'Shipping'
  },

  // Settings
  {
    action: 'read',
    subject: 'Settings'
  },
  {
    action: 'create',
    subject: 'Settings'
  },
  {
    action: 'update',
    subject: 'Settings'
  },
  {
    action: 'delete',
    subject: 'Settings'
  },
  //Trash
  {
    action: 'read',
    subject: 'Trash'
  },
  {
    action: 'create',
    subject: 'Trash'
  },
  {
    action: 'update',
    subject: 'Trash'
  },
  {
    action: 'delete',
    subject: 'Trash'
  },

  // PointOfSale
  {
    action: 'read',
    subject: 'PointOfSale'
  },
  {
    action: 'create',
    subject: 'PointOfSale'
  },
  {
    action: 'update',
    subject: 'PointOfSale'
  },
  {
    action: 'delete',
    subject: 'PointOfSale'
  },

  //Tools
  {
    action: 'read',
    subject: 'Tools'
  },
  {
    action: 'create',
    subject: 'Tools'
  },
  {
    action: 'update',
    subject: 'Tools'
  },
  {
    action: 'delete',
    subject: 'Tools'
  },

  //Birthdays
  {
    action: 'read',
    subject: 'Birthdays'
  },
  {
    action: 'create',
    subject: 'Birthdays'
  },
  {
    action: 'update',
    subject: 'Birthdays'
  },
  {
    action: 'delete',
    subject: 'Birthdays'
  },

  //Coupons
  {
    action: 'read',
    subject: 'Coupons'
  },
  {
    action: 'create',
    subject: 'Coupons'
  },
  {
    action: 'update',
    subject: 'Coupons'
  },
  {
    action: 'delete',
    subject: 'Coupons'
  },
  // Updates
  {
    action: 'read',
    subject: 'Updates'
  },
  {
    action: 'create',
    subject: 'Updates'
  },
  {
    action: 'update',
    subject: 'Updates'
  },
  {
    action: 'delete',
    subject: 'Updates'
  },

  // Reviews
  {
    action: 'read',
    subject: 'Reviews'
  },
  {
    action: 'create',
    subject: 'Reviews'
  },
  {
    action: 'update',
    subject: 'Reviews'
  },
  {
    action: 'delete',
    subject: 'Reviews'
  }
]
